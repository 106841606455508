import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vant from 'vant'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import NutBig from "@nutui/nutui-bingo";
import "@nutui/nutui-bingo/dist/style.css";
import 'amfe-flexible'
const app = createApp(App)
app.config.globalProperties.$cookies = VueCookies
app.config.globalProperties.$http = axios
createApp(App).use(store).use(router).use(vant).use(NutBig).mount('#app')
