// import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import HomeView from '../views/GuanWang.vue'
HomeView
import * as VueRouter from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '首页',
      // keepAlive: true,
      // Caching: true
    },
  },
  {
    path: "/HomeView",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "首页",
      keepAlive: true,
      Caching: true
    }
  },
  {
    path: "/login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      title: "登陆"
    }
  },
  {
    path: "/myInformation",
    component: () => import("../views/myInformation.vue"),
    meta: {
      title: "我的",
      keepAlive: true
    }
  }, {
    path: "/myTeam",
    component: () => import("../views/myTeam.vue"),
    meta: {
      title: "我的团队",
      keepAlive: false
    }
  }, {
    path: "/changePrice",
    component: () => import("../views/changePrice.vue"),
    meta: {
      title: "修改团队成员价格",
      keepAlive: false
    }
  }, {
    path: "/myOrder",
    component: () => import("../views/myOrder.vue"),
    meta: {
      title: "我的订单",
      keepAlive: false
    }
  },
  {
    path: "/DetailedRules",
    component: () => import("../views/DetailedRules.vue"),
    meta: {
      title: "详细规则",
      keepAlive: false
    }
  }, {
    path: "/myConfig",
    component: () => import("../views/myConfig.vue"),
    meta: {
      title: "设置",
      keepAlive: false
    }
  }, {
    path: "/setPassword",
    component: () => import("../views/setPassword.vue"),
    meta: {
      title: "修改密码",
      keepAlive: false
    }
  }, {
    path: "/configPassword",
    component: () => import("../views/configPassword.vue"),
    meta: {
      title: "设置密码",
      keepAlive: false
    }
  },
  {
    path: "/pullNewRewards",
    component: () => import("../views/pullNewRewards.vue"),
    meta: {
      title: "拉新奖励",
      keepAlive: false
    }
  },
  // {
  //   path: "/ZhuanPan",
  //   component: () => import("../views/ZhuanPan.vue"),
  //   meta: {
  //     title: "抽奖",
  //     keepAlive: false
  //   }
  // },

  {
    path: "/billingDetails",
    component: () => import("../views/billingDetails.vue"),
    meta: {
      title: "结算明细",
      keepAlive: false
    }
  }, {
    path: "/projectProject",
    component: () => import("../views/projectProject.vue"),
    meta: {
      title: "繁星计划",
      keepAlive: false
    }
  }, {
    path: "/withdrawDeposit",
    component: () => import("../views/withdrawDeposit.vue"),
    meta: {
      title: "提现",
      keepAlive: false
    }
  },
  {
    path: "/QRcode",
    component: () => import("../views/QRcode.vue"),
    meta: {
      title: "二维码",
      keepAlive: false
    }
  }, {
    path: "/QRcode2",
    component: () => import("../views/QRcode2.vue"),
    meta: {
      title: "招募代理",
      keepAlive: false
    }
  },
  {
    path: "/QRcode2",
    component: () => import("../views/QRcode2.vue"),
    meta: {
      title: "招募代理",
      keepAlive: false
    }
  },
  {
    path: "/addUser",
    component: () => import("../views/addUser.vue"),
    meta: {
      title: "团队增员",
      keepAlive: false
    }
  },

  {
    path: "/handleCard",
    component: () => import("../views/handleCard.vue"),
    meta: {
      title: "申请",
      keepAlive: false
    }
  }, {
    path: "/appRegistered",
    component: () => import("../views/appRegistered.vue"),
    meta: {
      title: "注册 ",
      keepAlive: false
    }
  }, {
    path: "/successR",
    component: () => import("../views/successR.vue"),
    meta: {
      title: "注册成功",
      keepAlive: false
    }
  },








  {
    path: "/ShareBonus",
    component: () => import("../views/ShareBonus.vue"),
    meta: {
      title: "团队分红",
      keepAlive: false
    }
  },

  {
    path: "/teamSubsidy",
    component: () => import("../views/teamSubsidy.vue"),
    meta: {
      title: "团队补贴",
      keepAlive: false
    }
  },
  {
    path: "/NewPullingActivity",
    component: () => import("../views/NewPullingActivity.vue"),
    meta: {
      title: "拉新活动",
      keepAlive: false
    }
  },

  {
    path: "/certificationCard",
    component: () => import("../views/certificationCard.vue"),
    meta: {
      title: "实名认证",
      keepAlive: false
    }
  },




]
const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
