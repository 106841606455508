<template>
  <div
    style="
      background: #ededed;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    "
  >
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.Caching" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.Caching" />
    </router-view>
  </div>

  <div v-if="$route.meta.keepAlive">
    <van-tabbar route active-color="#254eda" inactive-color="#000" v-model="active">
      <van-tabbar-item replace to="/HomeView">
        <template #icon="props">
          <img
            v-if="props.active"
            style="width: 0.7rem; height: 0.7rem; margin-top: 0.2rem"
            src="./assets/977c613dbbaa91981e80f85bee875b1.png"
            alt
            srcset
          />
          <img
            v-else
            style="width: 0.7rem; height: 0.7rem; margin-top: 0.2rem"
            src="./assets/0292298951dea5f98a06c2826be711d.png"
            alt
            srcset
          />
        </template>

        <div style="font-size: 0.35rem">首页</div>
      </van-tabbar-item>
      <van-tabbar-item replace to="/myInformation">
        <template #icon="props">
          <img
            v-if="props.active"
            style="width: 0.7rem; height: 0.7rem; margin-top: 0.2rem"
            src="./assets/6fef3bd551e0b581b03fd3bd7120dee.png"
            alt
            srcset
          />
          <img
            v-else
            style="width: 0.7rem; height: 0.7rem; margin-top: 0.2rem"
            src="./assets/7740077883aac24cdbadeec68a8dd0d.png"
            alt
            srcset
          />
        </template>
        <div style="font-size: 0.35rem">我的</div>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { createApp } from "vue";
import { Tabbar, TabbarItem } from "vant";
import "vant/lib/index.css";
const app = createApp();
// import { api } from "./biz/api";
app.use(Tabbar);
app.use(TabbarItem);
import { ref } from "vue";

export default {
  setup() {
    const active = ref(0);

    return { active };
  },
  mounted() {}
};
</script>
<style lang="less">
.van-tabbar--fixed {
  background: #fff;
}

.van-tabbar-item--active {
  background: #fff;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  font-size: 0.4rem;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.van-tabbar-item {
  font-size: 0.39rem;
}
.backgroundS {
  background: #254eda;
}
.backgroundQ {
  background: #cfdeff;
}
.colorS {
  color: #254eda;
}
.colorQ {
  color: #cfdeff;
}
.van-tabbar--fixed {
  height: 1.4rem;
  padding-bottom: 0.2rem;
}
#app {
  font-family: 微软雅黑;
}
</style>
