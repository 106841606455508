<template>
  <div></div>
</template>
<script>
export default {
  setup() {},
  mounted() {
    window.location.href = "https://www.ws101.cn/offa/index.html";
  }
};
</script>
<style lang="less" >
</style>